import { faker } from '@faker-js/faker'
import { InitiateProductSubscription, Price, ProductTypeSubject } from '@t16t/sdk';
import { sleep } from '../../utils/general';

type ApiResponse<T> = {
    errorMessage: string | undefined;
    data: T | undefined;
}

export class FakeProductService {

    async fetchSubjectProducts(): Promise<ApiResponse<ProductTypeSubject[]>> {
      await sleep(1000)
      const chanceOfFailure = Math.round(Math.random()) === 1;
      const data = chanceOfFailure? undefined : await this.generateFakeSubjectProducts();
        return {
            errorMessage: chanceOfFailure? 'Something went wrong' : undefined,
            data
        }
    }

    async subscribeToProduct(data: InitiateProductSubscription): Promise<ApiResponse<void>> {
      await sleep(500);
      const chanceOfFailure = Math.round(Math.random()) === 1;
        return {
            errorMessage: chanceOfFailure? 'Something went wrong' : undefined,
            data: undefined
        }
    }

    private generateFakeSubjectProducts(count: number = 10): ProductTypeSubject[] {
      const products = faker.helpers.multiple((): ProductTypeSubject => {
        return {
          entityId: faker.string.uuid(),
          type: 'SUBJECT',
          subject: {
            entityId: faker.string.uuid(),
            subjectEntityId: faker.string.uuid(),
            name: faker.word.noun(),
            numberOfQuestions: faker.number.int({ max: 200, min: 1 }),
            numberOfTopics: faker.number.int({ max: 20, min: 1 }),
            description: faker.lorem.text(),
            image: faker.image.urlLoremFlickr({ category: 'books', width: 574, height: 320 }),
          },
          prices: faker.helpers.multiple((): Price => {
            return {
              entityId: faker.string.uuid(),
              name: faker.word.words(),
              amount: {
                value: faker.number.int({ min: 10, max: 1000 }),
                scale: faker.number.int({ min: 0, max: 3 }),
                currency: 'LYD',
              },
              canPurchase: faker.datatype.boolean(),
            }
          }),
        }
      }, { count: {min: 0, max: count} })
      return products
    }
    
}
